.container {
    height: 100%;
    height: calc(100vh - 50px); /* 50px is the header's height */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    overflow: auto;
    font-size: 0.875rem;
}

.wrapperPanels {
    height: 100%;
    width: 100%;
    justify-content: stretch;
    overflow: hidden;
}

.panels {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    overflow: auto;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.verticalSplit {
    width: 100%;
}

.codeMirrorPanel {
    flex: 0 0 50%;
}

.codeMirrorPanelOptions {
    flex: 0 0 50%;
    height: 30%;
    border-bottom: 1.5px solid #ccc;
}

.codeMirrorPanelInput {
    flex: 0 0 50%;
    height: 70%;
}
