.container {
    background-color: #20232a;
    color: #fff;
    /* min-height: 50px; */
    padding: 16px 0;
    width: 100%;
    transform: translateZ(0);
}

.container header {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    flex-flow: row nowrap;
    position: relative;
    text-align: left;
    margin-left: 1.20%;

    align-items: center;
    justify-content: center;
}

.container header img {
    border-style: none;
    box-sizing: content-box;
    max-width: 100%;
    height: 100%;
    margin-right: 10px;
}

.container header img.small {
    max-width: 65%;
    height: 65%;
}

.container header div {
    margin-right: 4em;
}

.container header h2 {
    display: block;
    font-size: 1.25em;
    line-height: 18px;
    margin: 0;
    position: relative;
}

.container header h3 {
    display: block;
    font-size: 1.25em;
    line-height: 16px;
    margin: 0;
    position: relative;
    font-weight: 500;
}

.container a {
    -webkit-box-align: center;
    align-items: center;
    border: 0;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    height: 34px;
    text-decoration: none;
}
